import { APP_INITIALIZER, NgModule } from '@angular/core';
import { SeoService } from './providers/seo.service';

export function initializeApp(canonicalLinkService: SeoService): () => Promise<void> {
  return (): Promise<void> => canonicalLinkService.init();
}

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    SeoService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ SeoService ],
      multi: true
    },
  ]
})
export class CoreModule {
}
