export const environment = {
  firebase: {
    projectId: 'centro-terapeutico-cubic-d3fb2',
    appId: '1:421235960175:web:a2820f2e6197f1ee4aa09a',
    storageBucket: 'centro-terapeutico-cubic-d3fb2.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyAXScY_fpEvyxuMSqp85spdIokote7i3Kw',
    authDomain: 'centro-terapeutico-cubic-d3fb2.firebaseapp.com',
    messagingSenderId: '421235960175',
    measurementId: 'G-TLEVFP1MLW',
  },
  production: true
};
