<header>
  <app-header></app-header>
</header>

<main>
  <app-main></app-main>
</main>

<footer>
  <app-footer></app-footer>
</footer>
