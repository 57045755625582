import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  { path: 'sobre-nosotros', loadChildren: () => import('./modules/about/about.module').then(m => m.AboutModule) },
  { path: 'servicios', loadChildren: () => import('./modules/services/services.module').then(m => m.ServicesModule) },
  { path: 'equipo', loadChildren: () => import('./modules/team/team.module').then(m => m.TeamModule) },
  { path: 'contacto', loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule) },
  { path: 'politica', loadChildren: () => import('./modules/policies/policies.module').then(m => m.PoliciesModule) },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {
}
