import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ]
})
export class HeaderComponent implements OnInit {
  public isMobile: boolean = true;

  constructor(
    public breakpoint$: BreakpointObserver
  ) {
  }

  ngOnInit(): void {
    this.breakpoint$.observe('(max-width: 1023px)').subscribe(result => {
      this.isMobile = result.matches;
    });
  }

}
