<app-collaborators></app-collaborators>

<div class="min-h-[250px] flex flex-col p-4 md:p-20">

  <div class="flex-1 my-8 block lg:hidden">
    <p class="font-[GeosansLight] text-center text-2xl">¿Tienes preguntas?</p>
    <div class="flex justify-center">
      <div class="text-4xl w-fit">
        <a class="mx-2 icon icon-Phone" href="tel://+34605130039">
          <span class="path1"></span>
          <span class="path2 before:bg-brand-500 before:rounded-full before:content-['\e930']"></span>
        </a>
        <a class="mx-2 icon icon-Email" href="mailto:info@centroterapeuticocubic.es">
          <span class="path1"></span>
          <span class="path2 before:bg-brand-500 text-white before:rounded-full before:content-['\e932']"></span>
        </a>
        <a class="mx-2 icon icon-Map" href="https://goo.gl/maps/6yBXwDjTJQvRTBLH9" target="_blank">
          <span class="path1"></span>
          <span class="path2 before:bg-brand-500 text-white before:rounded-full before:content-['\e934']"></span>
        </a>
        <a class="mx-2 icon icon-Instagram" href="https://www.instagram.com/centroterapeuticocubic" target="_blank">
          <span class="path1"></span>
          <span class="path2 before:bg-brand-500 before:rounded-full before:content-['\e936']"></span>
          <span class="path3"></span>
          <span class="path4"></span>
        </a>
        <a class="mx-2 icon icon-Facebook" href="https://www.facebook.com/centroterapeuticocubic" target="_blank">
          <span class="path1"></span>
          <span class="path2 before:bg-brand-500 before:rounded-full before:content-['\e93a']"></span>
        </a>
      </div>
    </div>
  </div>

  <div class="flex-1 flex mb-12">

    <div class="flex-1 flex">
      <div class="flex-1 flex justify-center items-center px-[20%] lg:pl-0 lg:pr-[10%] 2xl:px-[20%]">
        <i class="icon icon-Logo text-4xl md:text-6xl xl:text-8xl"></i>
      </div>
      <div class="flex-2 my-8 hidden lg:flex lg:flex-col lg:justify-center">
        <p class="font-[GeosansLight] text-center md:text-left text-2xl xl:text-4xl">¿Tienes preguntas?</p>
        <div class="flex justify-center xl:mt-4">
          <div class="text-4xl xl:text-5xl w-fit">
            <a class="mx-2 md:ml-0 icon icon-Phone" href="tel://+34605130039">
              <span class="path1"></span>
              <span class="path2 before:bg-brand-500 before:rounded-full before:content-['\e930']"></span>
            </a>
            <a class="mx-2 icon icon-Email" href="mailto:info@centroterapeuticocubic.es">
              <span class="path1"></span>
              <span class="path2 before:bg-brand-500 text-white before:rounded-full before:content-['\e932']"></span>
            </a>
            <a class="mx-2 icon icon-Map" href="https://goo.gl/maps/6yBXwDjTJQvRTBLH9" target="_blank">
              <span class="path1"></span>
              <span class="path2 before:bg-brand-500 text-white before:rounded-full before:content-['\e934']"></span>
            </a>
            <a class="mx-2 icon icon-Instagram" href="https://www.instagram.com/centroterapeuticocubic" target="_blank">
              <span class="path1"></span>
              <span class="path2 before:bg-brand-500 before:rounded-full before:content-['\e936']"></span>
              <span class="path3"></span>
              <span class="path4"></span>
            </a>
            <a class="mx-2 icon icon-Facebook" href="https://www.facebook.com/centroterapeuticocubic" target="_blank">
              <span class="path1"></span>
              <span class="path2 before:bg-brand-500 before:rounded-full before:content-['\e93a']"></span>
            </a>
          </div>
        </div>
        <div class="flex flex-col">
          <a class="font-bold text-center md:text-left text-lg md:text-xs lg:text-base
                 underline flex gap-2" href="tel://+34605130039">
            <span class="min-w-[60px]">MOVIL:</span>
            <span>605 13 00 39</span>
          </a>
          <a class="font-bold text-center md:text-left text-lg md:text-xs lg:text-base
                 underline flex gap-2" href="tel://910344955">
            <span class="min-w-[60px]">FIJO:</span>
            <span>91 034 49 55</span>
          </a>
        </div>
      </div>
    </div>

    <div class="flex-1 flex justify-center items-center">

      <ul class="grid grid-cols-2 gap-2 xl:gap-8">
        <li>
          <a class="text-xs xl:text-2xl underline cursor-pointer" href="/sobre-nosotros">Acerca de</a>
        </li>
        <li>
          <a class="text-xs xl:text-2xl underline cursor-pointer"href="/servicios">Servicios</a>
        </li>
<!--        <li>-->
<!--          <a class="text-xs xl:text-2xl underline cursor-pointer">Blog</a>-->
<!--        </li>-->
        <li>
          <a class="text-xs xl:text-2xl underline cursor-pointer" href="/equipo">Equipo</a>
        </li>
        <li>
          <a class="text-xs xl:text-2xl underline cursor-pointer" href="/contacto">Contacto</a>
        </li>
        <li>
          <a class="text-xs xl:text-2xl underline cursor-pointer" href="tel://+34605130039">Reservar cita</a>
        </li>
        <li>
          <a class="text-xs xl:text-2xl underline cursor-pointer" href="/politica/privacidad">Privacidad</a>
        </li>
        <li>
          <a class="text-xs xl:text-2xl underline cursor-pointer" href="/politica/cookies">Cookies</a>
        </li>
        <li>
          <a class="text-xs xl:text-2xl underline cursor-pointer" href="/politica/legal">Legal</a>
        </li>
      </ul>
    </div>
  </div>

</div>
