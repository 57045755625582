export interface Collaborator {
  id: string;
  name: string;
  description: string;
  logo: string;
  idCategory: number;
  category?: CollaboratorCategory;
}

export interface CollaboratorCategory {
  id: number;
  name: string;
}

export const collaboratorsCategories: CollaboratorCategory[] = [
  {
    id: 1,
    name: 'ASOCIACIONES'
  },
  {
    id: 2,
    name: 'COLEGIOS / AMPAS'
  },
  {
    id: 3,
    name: 'UNIVERSIDADES'
  },
  {
    id: 4,
    name: 'AMIGOS DE CUBIC'
  },
]


// Asociaciones
// Colegios / AMPAS
// UNIVERSIDADES
// AMIGOS DE CUBIC
