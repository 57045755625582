<header class="c-header flex justify-between">
  <div class="flex items-center
  ml-8 md:ml-12 lg:ml-18 xl:ml-24 2xl:ml-28
  mt-8 md:mt-12 lg:mt-16 xl:mt-20
  mb-8 md:mb-12 lg:mb-16 xl:mb-20
">
    <a href="/">
      <i [matMenuTriggerFor]="menu" class="icon icon-Logo text-6xl lg:text-7xl xl:text-8xl"></i>
    </a>
  </div>
  <div *ngIf="isMobile" class="flex items-center mr-8 md:mr-12 lg:mr-16">
    <i [matMenuTriggerFor]="menu"
       class="icon icon-Menu text-xl"></i>
  </div>
  <div *ngIf="!isMobile" class="flex items-center m-0 lg:pr-10 xl:pr-16">
    <ul class="flex flex-row gap-4 xl:gap-8">
      <li class="flex items-center cursor-pointer font-semibold
                 text-base lg:text-xl">
        <a href="/sobre-nosotros">Acerca de</a>
      </li>
      <li class="flex items-center cursor-pointer font-semibold
                 text-base lg:text-xl">
        <a href="/servicios">Servicios</a>
      </li>
      <li class="flex items-center cursor-pointer font-semibold
                 text-base lg:text-xl">
        <a href="/equipo">Equipo</a>
      </li>
      <li class="flex items-center cursor-pointer">
        <a href="/contacto" mat-raised-button color="primary"
           class="font-bold
                       text-base lg:text-xl
                       py-2"
        >CONTACTO
        </a>
      </li>
    </ul>
  </div>
</header>
<mat-menu #menu="matMenu">
  <a mat-menu-item href="/sobre-nosotros">Acerca de</a>
  <a mat-menu-item href="/servicios">Servicios</a>
  <a mat-menu-item href="/equipo">Equipo</a>
  <!--  <a mat-menu-item href="/blog">Blog</a>-->
  <a mat-menu-item href="/contacto">Contacto</a>
</mat-menu>
