import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatMenuModule } from '@angular/material/menu';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CollaboratorsComponent } from '../layout/components/collaborators/collaborators.component';
import { GetDownloadURLPipeModule } from '@angular/fire/compat/storage';

const components: any[] = [
  CollaboratorsComponent
];
const directives: any[] = [];
const pipes: any[] = [];

const modules: any[] = [
  CommonModule,
  RouterModule,
  GoogleMapsModule,
  ReactiveFormsModule,
  GetDownloadURLPipeModule
];
const materialModules: any[] = [
  MatButtonModule,
  MatToolbarModule,
  MatIconModule,
  MatMenuModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatCheckboxModule
];

@NgModule({
  declarations: [
    components,
    directives,
    pipes
  ],
  imports: [
    modules,
    materialModules
  ],
  exports: [
    components,
    directives,
    pipes,
    modules,
    materialModules
  ]
})
export class SharedModule {
}
