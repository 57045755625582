import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MainComponent } from './components/main/main.component';
import { SharedModule } from '../shared/shared.module';
import { CollaboratorsComponent } from './components/collaborators/collaborators.component';
import { CollaboratorCardComponent } from './components/collaborators/collaborator-card/collaborator-card.component';


@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    CollaboratorCardComponent
  ],
  exports: [
    LayoutComponent
  ],
  imports: [
    SharedModule
  ]
})
export class LayoutModule {
}
