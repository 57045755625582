import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Meta } from '@angular/platform-browser';

@Injectable()
export class SeoService {

  constructor(@Inject(DOCUMENT) private doc: Document,
              private meta$: Meta) {
  }

  public init(): Promise<void> {
    return new Promise((resolve: any) => {
      this.createLinkForCanonicalURL();
      resolve();
    });
  }

  private createLinkForCanonicalURL(): void {
    const links: HTMLCollection = this.doc.getElementsByTagName('link');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < links.length; i++) {
      if (links[i].getAttribute('rel') === 'canonical') {
        links[i].setAttribute('href', this.doc.URL);
      }
    }
  }

  public updateMetaTag(metas: { name: string, content: string }[]): void {
    metas.forEach(meta => {
      this.meta$.updateTag(meta);
    });
  }
}
