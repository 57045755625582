import { Component } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import {
  collaboratorsCategories,
  Collaborator,
  CollaboratorCategory
} from './collaborator.model';

@Component({
  selector: 'app-collaborators',
  templateUrl: './collaborators.component.html',
  styleUrls: [ './collaborators.component.scss' ]
})
export class CollaboratorsComponent {

  public collaborators: Collaborator[] = [];
  public collaboratorsCategories: CollaboratorCategory[] = collaboratorsCategories;
  private collaboratorCollection: AngularFirestoreCollection<Collaborator>;

  constructor(private afStore: AngularFirestore) {
    this.collaboratorCollection = this.afStore.collection('collaborators');
    this.collaboratorCollection.valueChanges().subscribe(collaborators => {
      this.collaborators = collaborators;
    });
  }
}
