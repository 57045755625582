<div class="min-h-[150px] py-6 px-6 flex flex-col justify-evenly bg-orange-500 text-white">

  <p class="font-semibold text-4xl text-center text-white">Colaboradores</p>


  <div *ngFor="let category of collaboratorsCategories">

    <p class="font-semibold text-2xl text-center text-white mt-8">{{category.name}}</p>

    <div class="flex flex-col md:flex-row justify-center gap-8 flex-wrap">

      <ng-container *ngFor="let collaborator of collaborators">
        <div class="w-full md:w-[400px]" *ngIf="collaborator.idCategory === category.id">
          <div class="flex justify-center w-full md:w-[400px] rounded-2xl bg-white">
            <img loading="lazy" class="h-[150px]" [src]="('logos/' + collaborator.logo) | getDownloadURL"
                 alt="{{collaborator.name}}"/>
          </div>
        </div>
      </ng-container>

    </div>

  </div>

</div>
